import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';
import { Container } from './styles';
import { useFornecedorContext } from './hooks/useFornecedorContext';
import { AbaGeral } from './components/AbaGeral';
import { AbaCaracteristicas } from './components/AbaCaracteristicas';
import { AbaCondicao } from './components/AbaCondicao';
import { AbaCompra } from './components/AbaCompra';

const FornecedorContent: React.FC = () => {
  const {
    showSearch,
    setShowSearch,
    resetFormData,
    nameFornecedor,
    fornecedor,
    isUpdate,
    setIsUpdate,
    onRowClick,
    onDelete,
    tab,
    setTab,
    onSubmit,
    loader,
  } = useFornecedorContext();
  useEffect(() => {
    resetFormData();
  }, []);

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }
  return (
    <Container>
      {showSearch && (
        <Search
          codTela={24}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          title={
            nameFornecedor
              ? `Fornecedor - ${nameFornecedor}`
              : 'Cadastro de Fornecedor'
          }
          codTela={24}
          onSave={onSubmit}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          codigoRegistro={[{ value: fornecedor, des_campo: 'Código' }]}
          isUpdate={isUpdate}
          onNew={() => {
            setIsUpdate(false);
            resetFormData();
          }}
          onDelete={() => onDelete()}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setIsUpdate(false);
            resetFormData();
            setShowSearch(true);
          }}
          showSwitch={false}
          labelButtonTrash="Inativar"
          deleteMessage="Deseja realmente inativar o fornecedor?"
        >
          <br />
          <Tabs
            id="controlled-tab-cliente"
            activeKey={tab}
            onSelect={(k) => setTab(k)}
          >
            <Tab eventKey="geral" title="Geral">
              <AbaGeral />
            </Tab>
            <Tab eventKey="caracteristicas" title="Características">
              <AbaCaracteristicas />
            </Tab>
            <Tab eventKey="condicao" title="Condição">
              <AbaCondicao />
              <br />
            </Tab>
            <Tab eventKey="compra" title="Compra">
              <AbaCompra />
            </Tab>
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};
export default FornecedorContent;
